.credit-debit{
    margin-top: 82px;
    padding: 30px 0px;
    h2{
        background: #dbdbdb;
        padding: 16px 10px;
        font-size: 13px;
        font-weight: 600;
        border-radius: 5px;
    }
    label{
        color: black;
        margin-bottom: 3px;
        font-size: 13px;
        font-weight: 500;
    }
    input{
        width: 100%;
        border: 1px solid #cbc8c8;
        color: black;
        border-radius: 8px;
        &::placeholder{
            color: black !important;
        }
    }
    select{
        width: 100%;
        border: 1px solid #cbc8c8;
        color: black;
        border-radius: 8px;
        background: #eef2f6;
        padding: 10px 10px;
    
    }
    button{
        margin-top: 10px;
        border: none;
        
    }
}