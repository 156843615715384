.view-main {
    padding: 110px 20px 20px;

    // heading
    .view-main-heading {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h5 {
            font-size: 16px;
            margin-bottom: 0;
        }

        .breadcrumb {



            a {
                color: var(--txt-black);
                text-decoration: none;
            }
        }

        .toggle-button {
            display: none;
        }


    }

    // view-u-sec 
    .view-u-sec {
        padding-top: 20px;
        --border-ui: #ddd;

        .row {
            row-gap: 20px;
        }

        .main-row {}

        .card-ui-heading {
            font-size: 14px;
        }

        .card-ui-sub-heading {
            color: #736d6d;
            font-size: 13px;
        }

        .react-tabs__tab-panel {
            height: 100%;
        }

        .card-view-11 {
            background-color: #fff;
            height: 100%;
            padding: 20px;
            border-radius: 10px;
            position: relative;
            z-index: 1;
            overflow: hidden;


            &::after {
                background-image: url(https://giccaptial.online/static/media/wave1.931f9613923ddfe2d991.svg);
                background-repeat: no-repeat;
                border-radius: 10px;
                content: "";
                height: 100%;
                left: 0;
                opacity: .8;
                position: absolute;
                top: 0;
                width: 100%;
                z-index: -1;
            }

            &::before {
                background-image: url(https://giccaptial.online/static/media/wave1.931f9613923ddfe2d991.svg);
                background-repeat: no-repeat;
                border-radius: 10px;
                transform: rotate(180deg);
                content: "";
                height: 100%;
                left: 0;
                opacity: .8;
                position: absolute;
                bottom: 0;
                width: 100%;
                // z-index: -1;
            }


        }


        .user-income-card {
            background-color: #fff;
            border: 1px solid var(----border-ui);
            box-shadow: 0px 0px 10px 0px #ddd;
            padding: 15px 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: row;
            height: 100%;

            .u-income-left {
                font-size: 15px;
                text-transform: capitalize;

                p {
                    margin-bottom: 0;
                }

                .u-balanace {
                    color: var(--primary-color);
                    font-size: 20px;
                }
            }

            .u-icome-ryt {

                .u-icon-img-outer {
                    width: 50px;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #ddd;
                    border-radius: 50%;

                    svg {
                        color: var(--primary-color);
                        font-size: 32px;

                    }
                }
            }
        }


        .history-card {
            background-color: #fff;
            border: 1px solid var(----border-ui);
            box-shadow: 0px 0px 10px 0px #ddd;
            padding: 15px 20px;
            height: 100%;

            .react-tabs {
                height: 100%;
            }


            .card-heading-history {
                margin-bottom: 10px;

                .react-tabs__tab-list {
                    background-color: #dddddd4a;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    border: 0;
                    column-gap: 10px;
                    font-size: 14px;
                    margin: 20px 0;

                    .react-tabs__tab {
                        display: inline-block;
                        padding: 8px 15px;
                        bottom: 0;
                        border-radius: 5px;
                        border: 1px solid var(--border-ui);

                        &::after {
                            display: none;
                        }
                    }

                    .react-tabs__tab--selected {
                        background-color: var(--primary-color);
                        color: var(--txt-white);

                    }

                }

            }

            .card-body {
                padding: 0;


                .h-js-1 {
                    height: 545px;
                    overflow: scroll;

                    .header-h-js {
                        background: -webkit-linear-gradient(top, rgba(43, 56, 146, 0.6) 0%, rgba(43, 56, 146, 0.94) 100%);
                        color: var(--txt-white);
                        position: sticky;
                        top: 0;
                        left: 0;
                        width: 100%;
                        overflow: scroll;
                    }

                    .hj-2 {
                        border-bottom: 1px solid var(--border-ui);
                       

                        &:last-child {
                            border: 0;
                        }

                        ul {
                            list-style-type: none;
                            padding: 0;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin-bottom: 0;
                            // overflow: hidden;

                            li {
                                flex-basis: 25%;
                                width: 100%;
                                font-size: 14px;
                                padding: 15px 5px;
                                white-space: nowrap;

                                &:nth-child(2) {
                                    text-align: right;
                                }

                                &:last-child {
                                    flex-basis: 50%;
                                    text-align: right;
                                }
                            }
                        }
                    }

                    .hj-2NH-header{
                        ul {

                            li {
                                padding: 15px 20px;
                            }
                        }

                    }

                    .hj-2NH{
                        ul {

                            li {
                                padding: 15px 20px;
                            }
                        }

                    }

                }

                .pagination {
                    padding: 10px 0;

                    .page-link {
                        border: 1px solid var(--primary-color);
                        font-size: 13px;
                        border-radius: 3px;
                        margin: 0 3px;
                        padding: 4px 10px;
                        color: var(--txt-black);
                        transition: all 0.4s linears;

                        &:hover {
                            background-color: var(--primary-color);
                            color: var(--txt-white);
                            border-color: currentColor;
                        }
                    }

                    .active {
                        a {
                            color: var(--txt-white);
                            background-color: var(--primary-color);
                        }
                    }
                }
            }


        }

        // user-info all
        .user-info {
            background-color: #fff;
            border: 1px solid var(----border-ui);
            box-shadow: 0px 0px 10px 0px #ddd;
            padding: 20px;
            height: 100%;

            .card-header {
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                border-bottom: 0;
                padding: 0;
                background-color: transparent;

                .user-avtar {
                    width: 54px;
                    height: 54px;
                }

                .user_iD {
                    color: var(--primary-color);
                    font-size: 13px;
                    margin-top: 5px;
                }


                .u-info-name {
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    width: 100%;
                    border-bottom: 1px solid var(--border-ui);
                    padding-bottom: 15px;
                    margin-top: 10px;

                    p {
                        margin-bottom: 0;
                        font-size: 16px;
                        color: var(--txt-black);
                        font-weight: 600;
                    }

                    h6 {
                        font-weight: 400;
                        color: var(--txt-black);
                        opacity: 0.7;
                        font-size: 14px;
                        margin-bottom: 0;
                    }
                }

                .u-info-settings {
                    cursor: pointer;

                    .dropdown-toggle {
                        background-color: transparent;
                        padding: 5px 3px;
                        color: var(--txt-black);
                        border-radius: 5px;
                        border: 1px solid var(--primary-color);
                        color: var(--primary-color);
                        transition: all 0.4s linear;


                        &:focus {
                            background-color: var(--primary-color);
                            color: var(--txt-white);
                            border-color: currentColor;
                        }
                    }

                    .dropdown-menu-start {
                        transform: translate3d(-126px, 42px, 0px) !important;

                        @media ($breckpoint_LG) {
                            transform: translate3d(0px, 40px, 0px) !important;
                        }

                        .dropdown-item {
                            font-size: 14px;
                            transition: all 0.4s linear;

                            &:hover {
                                color: var(--primary-color);
                            }
                        }


                    }

                    .dropdown-toggle::after {
                        display: none;
                    }

                }
            }

            .card-body-sec {
                padding: 10px 0;
                width: 100%;


                .user-team-j1 {
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    flex-direction: column;
                    width: 100%;

                    .j1-team {
                        width: 100%;

                        P {
                            font-size: 14px;
                            margin-bottom: 5px;
                        }

                        h6 {
                            font-size: 14px;
                            font-weight: 600;
                            margin-bottom: 0;
                        }

                    }


                }

            }

            .card-footer {
                height: 100%;
                padding: 0;
                margin-top: 40px;
                padding-top: 20px;
                border-top: 1px solid var(--border-ui);
                background-color: transparent;


                .react-tabs__tab-list {
                    background-color: transparent;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    row-gap: 13px;
                    border: 0;
                    column-gap: 10px;
                    font-size: 14px;
                    margin: 20px 0;

                    .react-tabs__tab {
                        display: block;
                        width: 100%;
                        padding: 10px 15px;
                        bottom: 0;
                        border-radius: 10px;
                        border: 1px solid var(--border-ui);

                        &::after {
                            display: none;
                        }
                    }

                    .react-tabs__tab--selected {
                        background-color: var(--primary-color);
                        color: var(--txt-white);

                    }

                }

            }
        }


        .edit-user {
            background-color: #fff;
            border: 1px solid var(----border-ui);
            box-shadow: 0px 0px 10px 0px #ddd;
            padding: 20px 30px;
            height: 100%;

            .edit-button {
                border: 1px solid var(--primary-color);
                color: var(--primary-color);
                padding: 5px 10px;
                font-size: 14px;
                text-transform: capitalize;
                transition: all 0.3s linear;

                &:hover {
                    background-color: var(--primary-color);
                    color: var(--txt-white);
                    border-color: currentColor;
                }
            }

            .card-body {
                padding: 0;
                padding-top: 20px;

                .fname-sec {
                    @media ($breckpoint_SM) {
                        margin-top: 20px;
                    }
                }

                .form-label {
                    font-size: 13px;
                }

                .form-control {
                    padding: 10px 10px;
                    border-radius: 7px;
                    border-color: var(--border-ui);
                    transition: all 0.4s linear;
                    box-shadow: unset;
                    overflow: unset;


                    // &:focus{
                    //     border-color: var(--primary-color);
                    // }

                    &::placeholder {
                        font-size: 14px;
                        color: var(--txt-black);
                        opacity: 0.7;
                    }
                }

                .btn {
                    display: inline-block;
                    padding: 8px 20px;
                    text-transform: capitalize;
                    border-radius: 40px;
                    font-size: 14px;
                }
            }

            .f-js-show {
                .form-control {
                    border-color: var(--primary-color) !important;
                    background-color: #c6e5f736 !important;
                }
            }
        }
    }





    // new 

    .leg_card {
        border: 1px solid var(----border-ui);
        box-shadow: 0px 0px 10px 0px #ddd;

        .card-body {
            padding: 29px 15px;
        }

        .inner_data_values {

            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }



            .item-sec {
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color: #dddddda3;
                padding: 10px;
                border-radius: 10px;

                p {
                    margin-bottom: 0;
                }
            }
        }

    }




}