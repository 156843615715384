.down-level{
    // margin-top: 100px;
    // padding: 40px 0px;
    .card-view-11{
        // background-color: #fff;
        height: 100%;
        padding: 20px;
        border-radius: 10px;
        position: relative;
        z-index: 1;
        overflow: hidden;
        margin: 100px 0px;
        .edit-user{
            background-color: #fff;
            border: 1px solid var(----border-ui);
            box-shadow: 0px 0px 10px 0px #ddd;
            padding: 20px 30px;
            height: 100%;
            border-radius: 10px;
            width: 50%;
            margin: auto;
            .input-div{
                margin: 5px 0px;
                label{
                    font-size: 12px;
                    margin-bottom: 10px;
                }
                input{
                    padding: 10px 10px;
                    border-radius: 7px;
                    border-color: 1px solid red;
                    transition: all 0.4s linear;
                    box-shadow: unset;
                    overflow: unset;
                    &:focus{
                        outline: none;
                        box-shadow: none;
                    }
                }
                .select-input{
                    padding: 10px 10px;
                    border-radius: 7px;
                    border-color: 1px solid red;
                    transition: all 0.4s linear;
                    box-shadow: unset;
                    overflow: unset;
                    &:focus{
                        outline: none;
                        box-shadow: none;
                    }
                }
            }
        
        }
    }
}