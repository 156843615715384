.main-form-page {
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  z-index: 1;
  width: 100%;
}

.main-form-page::after {
  position: absolute;
  background-image: url(../images/1.jpeg);
  content: "";
  height: 100%;
  z-index: -1;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  //   filter: brightness(0.6);
  display: none;
}

.form-design {
  display: flex;
  justify-content: center;
}

.sub-title {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  background: #000;
  padding: 50px;
  box-shadow: 0 0 35px rgb(3 192 232 / 10%);
  backdrop-filter: blur(10px);
  width: 35%;

  @media ($breckpoint_LG) {
    width: 70%;
  }

  @media ($breckpoint_SM) {
    width: 90%;
  }

  @media ($breckpoint_SM) {
    margin: 0 30px;
    padding: 25px;
  }

  @media ($breckpoint_ESM) {
    margin: 0 15px;
    padding: 15px;
  }
}

.login-heading {
  text-align: center;
}

.login-heading img {
  height: 90px;
  margin: auto;
  width: auto;
}

.login-heading h4 {
  text-align: center;
  font-size: 28px;
  font-weight: 500;
  font-family: $font_family_2;
  color: var(--txt-white);
}

.login-heading a {
  text-decoration: none;
}

.login-heading p {
  text-align: center;
  color: var(--txt-white);
}

.panel-form {}

.user-field {
  padding-bottom: 20px;
  position: relative;
}

.user-field i {
  position: absolute;
  top: 12px;
  font-size: 18px;
  right: 9px;
  color: var(--ferozi-color);
}

.password-field {
  padding-bottom: 20px;
  position: relative;
}

input {
  width: 100%;
  padding: 10px 30px 10px 15px;
  color: var(--txt-white);
  background-color: transparent;
  border-radius: 2px;
  border: 1px solid var(--primary-color);
  outline: none;
  font-weight: 500;
  font-size: 14px;
}

input::placeholder {
  color: var(--txt-white);
}

.password-field i {
  position: absolute;
  top: 12px;
  font-size: 18px;
  right: 9px;
  color: var(--ferozi-color);
}

.main-remember {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
}

.remember-me {
  display: flex;
  align-items: center;
  gap: 7px;
}

.me-label {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  color: var(--txt-white);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: var(--txt-white);
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #23232300;
}

.forget-pass a {
  text-decoration: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  color: var(--txt-white);
}

.login-btn {
  padding-bottom: 16px;
  display: flex;
  justify-content: center;
}

.button_login {
  width: 100%;
  background-color: var(--primary-color);
  padding: 9px 18px;
  border-radius: 10px;
  font-size: 14px;
  color: var(--txt-white);
  text-decoration: none;
  transition: all 0.3s linear;
  border: 2px solid transparent;
  margin-top: 25px;

  &:hover {
    border-color: var(--primary-color);
    background-color: transparent;
    color: #fff;
  }
}

.create-account {
  display: flex;
  justify-content: center;
}

.create-account a {
  text-decoration: none;
  color: var(--txt-black);
}