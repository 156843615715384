:root {
  --body: #eef2f6;
  --txt-white: #fff;
  --txt-black: #000;
  --primary-color: #1eaadb;
  --secondary-color:#31bafd;
  --border_color: rgb(227, 232, 239);
  --card_bg: #fff;
  --hover_bg: #eef2f6;
  // --hover_bg: #31bafd1f;
  --heading-new-bg:#dcebff;
  --red:#ff0000de;
  --green: green;

  
  --lightblue-color: #1ab1d1;
  --ferozi-color: #03c0e8;
  --field-color: #0d2d4f;
  --input-color: #83d3ff;
  --lightpurple-color: #9969ee;
  --header-bg: #000;
}
