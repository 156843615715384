.header-sec {
  width: 100%;
  background-color: var(--header-bg);
  // border-bottom: 1px solid var(--border_color);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  box-shadow: 16px 0px 16px rgba(24, 23, 38, 0.06);

  .inner-sec {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;
      height: 80px;
      gap: 40px;
      // justify-content: space-evenly;
      width: 100%;


      @media ($breckpoint_XL) {
        gap: 20px;
      }

      .logo {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          height: 50px;
          width: auto;

          @media ($breckpoint_XL) {
            height: 44px;
          }

          @media ($breckpoint_SM) {
            height: 40px;
          }

          @media ($breckpoint_ESM) {
            height: 35px;
          }
        }
      }

      .links {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        @media ($breckpoint_XL) {
          gap: 3px;
        }

        @media ($breckpoint_LG) {
          display: none;
        }

        .nav_links {
          background-color: transparent;
          padding: 6px 13px;
          border-radius: 10px;
          font-size: 14px;
          color: var(--txt-white);
          text-decoration: none;
          transition: all 0.3s linear;
          border: 1px solid transparent;
          cursor: pointer;
          font-weight: 500;

          @media ($breckpoint_XL) {
            padding: 6px 8px;
          }

          &:hover {
            background-color: rgba(255, 255, 255, 0.04);

            .dropdown {
              display: block;
            }
          }

          &::after {
            display: none;
          }
        }

        // .active {
        //   background-color: var(--primary-color);

        //   &:hover {
        //     background-color: var(--hover_bg);
        //   }
        // }

        .dropdown {
          position: relative;
          z-index: 1;
          padding: 20px 0;

          &:hover {
            .dropdown-menu {
              display: block;
            }
          }

          .dropdown-menu {
            padding: 15px 0;
            background-color: var(--card_bg);
            border: 1px solid var(--primary-color);
            border-radius: 10px;
            // display: none;
            position: absolute;
            top: 64px;
            left: 0;
            width: 245px;
            border: 1px solid var(--border_color);
            box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);

            a {
              padding: 10px 14px;
              font-size: 13px;
              font-weight: 500;
              position: relative;
              color: var(--txt-black);
              display: block;
              text-decoration: none;



              svg {
                position: absolute;
                top: 13px;
                right: 10px;
                font-size: 16px;
              }

              &:hover {
                background-color: var(--hover_bg);
                color: var(--primary-color);

                svg {
                  color: var(--primary-color);
                }
              }
            }

            .drp-item {
              padding: 0 30px !important;
            }

            .dropdown_item {

              padding: 10px 14px;
              font-size: 13px;
              font-weight: 500;
              position: relative;
              color: var(--txt-black);
              display: block;
              text-decoration: none;
              cursor: pointer;

              &:hover {

                .dropdown_menu2 {
                  display: block;
                }
              }



              svg {
                position: absolute;
                top: 13px;
                right: 10px;
                font-size: 16px;
              }

              .dropdown_menu2 {
                display: none;
                position: absolute;
                top: 0;
                right: -100%;
                width: 245px;
                padding: 15px 0;
                background-color: var(--card_bg);
                border: 1px solid var(--primary-color);
                border-radius: 10px;
                // display: none;
                border: 1px solid var(--border_color);
                box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
                border-left: 0 !important;


                &::after {
                  content: "";
                  position: absolute;
                  top: -11px;
                  left: -21px;
                  width: 30px;
                  height: 60px;
                  border-right: solid 30px #ddd;
                  border-bottom: solid 30px transparent;
                  border-top: solid 30px transparent;
                  transform: scale(0.4);
                }


              }

            }
          }
        }
      }
    }

    .right_main {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 14px;

      .right {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer;
        height: 80px;

        &:hover {
          .profile_dropdown {
            display: block;
          }
        }



        img {
          height: 45px;
          width: auto;

          @media($breckpoint_SM) {
            height: 40px;
          }

          @media ($breckpoint_ESM) {
            height: 35px;
          }
        }

        .profile_dropdown {
          position: absolute;
          top: 80px;
          right: 0;
          background-color: var(--card_bg);
          border-radius: 0 0 10px 10px;
          padding: 20px;
          width: 300px;
          border: 1px solid var(--border_color);
          box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175);
          display: none;

          .profile_info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 10px;
            border-bottom: 1px solid var(--border_color);
            color: var(--txt-black);

            .left_inner {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              gap: 10px;

              img {
                height: 30px;
                width: auto;
              }
            }

            .right_inner {
              svg {
                padding: 13px;
                height: 52px;
                width: 52px;
                border-radius: 50%;
                color: var(--primary-color);
                transition: all 0.3s linear;

                &:hover {
                  background-color: rgba(255, 255, 255, 0.04);
                }
              }
            }
          }

          .inner_links {
            a {
              display: block;
              padding: 12px;
              color: var(--txt-black);
              font-size: 14px;
              background-color: transparent;
              border-radius: 10px;
              position: relative;
              text-decoration: none;
              margin-top: 10px;
              font-weight: 600;
              transition: all .3s linear;

              svg {
                position: absolute;
                top: 10px;
                right: 10px;
              }

              &:hover {
                background-color: var(--hover_bg);
                color: var(--primary-color);

                svg {
                  color: var(--primary-color);
                }
              }
            }
          }
        }
      }

      .logout_btn {
        .main_button {
          @media($breckpoint_SM) {
            padding: 5px 10px;
          }

        }
      }

      .bar___pvt {
        width: 35px;
        height: 26px;
        // background: var(--primary-color);
        // box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.08);
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        overflow: hidden;
        display: none;
        // padding: 15px 20px;


        @media ($breckpoint_LG) {
          display: flex;
        }

        @media($breckpoint_ESM) {
          width: 26px;
        }

        .line-toggle {
          position: absolute;
          display: block;
          width: 30px;
          height: 4px;
          background: #fff;
          border-radius: 2px;
          transition: all 0.6s;

          @media($breckpoint_SM) {
            width: 20px;
          }



          &:first-child {
            transform: translateY(-10px) translateX(-8px);
            width: 20px;
            transition-delay: 0s;

            @media($breckpoint_SM) {
              width: 15px;
            }

            @media ($breckpoint_ESM) {
              width: 12px;
            }
          }

          &:nth-child(2) {
            transition-delay: 0.12s;


          }

          &:last-child {
            transform: translateY(10px) translateX(-9px);
            width: 20px;
            transition-delay: 0s;

            @media($breckpoint_SM) {
              width: 15px;
            }

            @media ($breckpoint_ESM) {
              width: 12px;
            }
          }
        }
      }
    }
  }
}

// footer css

.footer_sec {
  background-color: var(--header-bg);
  border-top: 1px solid var(--border_color);
  position: fixed;
  bottom: 0px;
  width: 100%;
  padding: 20px;
  z-index: 1;
  box-shadow: 16px 0px 16px rgba(24, 23, 38, 0.06);
  // border-radius: 10px;
  // margin-top: 40px;


  .inner_data {
    text-align: center;

    p {
      margin-bottom: 0px;

      a {
        text-decoration: none;
      }

      @media($breckpoint_SM) {
        font-size: 15px;
      }
    }
  }
}