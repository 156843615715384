.tabs_rows {
    display: flex;
    justify-content: space-between;

    .left_sec {
        height: 100%;
      
        @media($breckpoint_MD){
            margin-bottom: 25PX;
        }

        .heading_2 {
            font-size: 30px;
            color: var(--primary-color);
            font-weight: 500;
            margin-bottom: 20px;

            
            @media($breckpoint_SM) {
                font-size: 25px;
            }
        }

        p{
            font-size: 16px;
            padding-top: 5px;

            @media($breckpoint_SM){
                font-size: 15px;
              
            }
        }

        ul {
            border-left: 3px dashed var(--primary-color);
            list-style: none;
            padding-left: 0px;
            margin-left: 20px;
            margin: 30px 0;
            margin-left: 10px;

            @media($breckpoint_SM){
                margin-left: 10px;
            }

            li {
                position: relative;
                padding: 0 0 20px 50px;
                font-size: 16px;
                color: var(--txt-black);
                opacity: .7;

                @media($breckpoint_SM){
                    font-size: 14px;
                }



                .icon_box {
                    position: absolute;
                    left: 6px;
                    top: 0;
                    z-index: 9;
                    font-size: 16px;
                    padding: 10px;
                    background-color: var(--heading-new-bg);
                    padding: 3px 6px;
                    border-radius: 50px;
                    border: 1px dotted var(--primary-color);

                    svg {
                        color: var(--primary-color);
                        font-size: 18px;
                    }
                }
            }
        }

        .balance_flex {
            display: flex;
            justify-content: space-between;
            width: 100%;
            background-color: var(--card_bg);
            border-radius: 5px;
            margin-top: 20px;
            padding: 10px;
            flex-wrap: wrap;

          

            .balance {
                width: 32%;
                padding: 10px;
                text-align: center;

                @media($breckpoint_SM){
                    width: 50%;
                }


                h6 {
                    font-size: 25px;
                    color: var(--primary-color);

                    @media($breckpoint_MD){
                        font-size: 20px;
                    }

                }

                h5 {
                    font-size: 18px;
                    color: var(--txt-black);
                    opacity: .7;

                    @media($breckpoint_MD){
                        font-size: 15px;
                    }
                }
            }

            .hr_row {
                border-right: 1px solid var(--border_color);
            }
        }
    }





}

.react_tabs_main {
    background-color: var(--card_bg);
    border: 1px solid var(--border_color);
    border-radius: 10px;
    height: 100%;

    .react-tabs {
        .react-tabs__tab-list {
            border: none;
            display: flex;
            justify-content: space-around;
            align-items: center;
            list-style: none;
            margin-bottom: 0px;

            .react-tabs__tab {
                font-size: 20px;
                color: var(--txt-black);
                font-weight: 500;
                width: 50%;
                border: none;
                text-align: center;
                border-radius: 3px 100px 3px 3px;
                padding: 20px 0px;

                @media($breckpoint_SM) {
                    padding: 15px 0px;
                    font-size: 18px;
                }
            }

        }

        .react-tabs__tab-list .debit_btn {
            border-radius: 100px 3px 3px 3px !important;
        }

        .react-tabs__tab-list .react-tabs__tab--selected.debit_btn {
            background: var(--red) !important;
        }

        .react-tabs__tab-list .react-tabs__tab--selected.credit_btn {
            background: var(--green) !important;
        }

        .react-tabs__tab--selected {
            background-color: var(--primary-color);
            color: var(--txt-white) !important;
        }

        .inner_tabpanel {
            padding: 20px;
            text-align: left;

            .form-group{
                label{
                    display: block;
                    text-align: left;
                    margin-bottom: 10px;
                }
            }
        }


    }

    .react-tabs__tab:focus:after {
        background-color: transparent;
    }
}

.debit_btn_bg {
    background-color: var(--red) !important;
    border: 1px solid var(--red) !important;
    width: 100% !important;
}

.credit_btn_bg {
    background-color: var(--green) !important;
    border: 1px solid var(--green) !important;
    width: 100% !important;
}


.fund-gic{
    padding-top: 150px;
    
}