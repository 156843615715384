.table-sec {
  color: var(--txt-black);


  .table_bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    padding: 20px;

    @media($breckpoint_SM) {
      display: block;
    }

    .table_data_text {
      p {
        color: var(--txt-black);
        font-size: 15px;

        @media($breckpoint_SM) {
          text-align: center;
        }
      }
    }
  }



  // new table work.... 

  .table-sec-subheader {
    background-color: #fdfdfde3;
    padding: 0 0 100px;

    .details-sec {
      margin: 50px 0;
      transition: all 0.4s linear;

      .row {
        row-gap: 20px;
      }


      .table-details {
        padding: 15px;

        .card-header {
          padding: 0;
          background-color: transparent;
          border-bottom: 0;
          display: flex;
          align-items: center;

          .card-imgs {
            position: relative;
            z-index: 1;
            display: inline-block;

            .icon {
              position: absolute;
              top: 15px;
              right: 5px;
              font-size: 35px;
              color: var(--primary-color);
              transition: all 0.4s linear;

            }
          }





        }

        &:hover {

          .card-imgs {
            .icon {
              top: 5px;
              right: 13px;
              transform: scale(0.9);

            }
          }

        }

        .de-sc {
          display: inline-block;
          width: 20px;
          margin-right: 20px;
          height: 10px;
          background-color: var(--primary-color);
          margin-left: 20px;
        }

        .users-txt {
          font-size: 15px;
        }

        .active-txt {
          color: var(--primary-color) !important;
        }
      }
    }
  }


  .table_main_new {
    margin-top: -90px;
    background-color: var(--card_bg);
    border-radius: 10px;
    border: 1px solid #dddddd94;
    margin-bottom: 30px;
    .view-btn{
      background: -webkit-linear-gradient(top, rgba(43, 56, 146, 0.6) 0%, rgba(43, 56, 146, 0.94) 100%);
      text-decoration: none;
      padding: 10px 20px;
      color: var(--txt-white);
      border-radius: 7px;
    }
    .heading {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media ($breckpoint_SM) {
        flex-direction: column;
        align-items: flex-start;
      }

      h1 {
        padding: 25px 15px;
        font-size: 22px;
        color: var(--primary-color);
        background-color: transparent;
        margin-bottom: 0px;

        @media($breckpoint_SM) {
          font-size: 20px;
          padding: 25px 15px 0;
        }
      }

      form {
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 15px;

        @media ($breckpoint_MD) {
          width: 50%;
        }

        @media ($breckpoint_SM) {
          padding: 0 15px;
          width: 100%;
        }

        .form-select {
          background-color: transparent !important;
          box-shadow: unset;

          &:focus {
            border-color: var(--primary-color) !important;
          }
        }

        .search_button {
          padding: 8px 15px;
          margin-left: 0px;
        }

        .input-fields {
          width: 100%;
          padding: 8px;
          color: var(--txt-black);
          background-color: transparent;
          border-radius: 5px;
          border: 1px solid var(--border_color);
          outline: none;
          font-weight: 500;
          font-size: 14px;

          &::placeholder {
            color: var(--txt-black);
          }

          &:focus {
            border-color: var(--primary-color) !important;
          }
        }
      }


    }

    .table-sec-new {
      padding: 0 10px;
    }



    table {

      thead {
        background: -webkit-linear-gradient(top, rgba(43, 56, 146, 0.6) 0%, rgba(43, 56, 146, 0.94) 100%);
      }

      tbody {
        tr {

          &:hover {
            background-color: var(--body);
          }
        }
      }

      th {
        font-size: 15px;
        font-weight: 500;
        color: var(--txt-white);
        // border-bottom: 3px solid var(--border_color);
        text-align: left;
        padding: 15px 16px;
        white-space: nowrap;
        background-color: transparent;
        position: relative;
        text-align: center;
      }

      td {
        font-size: 13px;
        font-weight: 400;
        text-align: center;
        padding: 18px 16px;
        color: var(--txt-black);
        white-space: nowrap;
        border-bottom: 1px solid var(--border_color);
        background-color: transparent;

        .button {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 10px;
        }
      }
    }

    .justifycontent {
      display: flex;
      align-items: center;
    }
  }
}

.in-table {

  .top-simple-heading {
    background-color: #fdfdfde3;
    text-align: center;
    padding: 160px 20px 150px;


    .heading-h1 {
      display: inline-block;
      position: relative;
      z-index: 1;
      padding-bottom: 10px;

      &::after {
        position: absolute;
        content: "";
        bottom: -9px;
        // left: 0;
        left: 50%;
        width: 50%;
        transform: translate(-50%, -50%);
        height: 10px;
        margin: auto;
        background-color: var(--primary-color);
        border-radius: 10px;
      }
    }

    .appro {
      margin-top: 60px;
      text-align: center;
    }

    .top-search {
      max-width: 500px;
      width: 100%;
      margin: auto;
      padding: 0;

      form {


        .top-sech {
          display: flex;
          align-items: center;
          gap: 10px;
          width: 100%;
          margin: 20px 0;
          border: 1px solid #ddd;
          border-radius: 10px;
          padding: 0 10px;
          transition: all 0.3s linear;

          &:hover {
            border-color: var(--primary-color);
          }

        }

        .input-fields {
          height: 55px;
          width: 100%;
          border: 0;
          padding: 0;
          color: #000;

          &::placeholder {
            color: #000;
          }
        }




        .search-bs {
          background-color: var(--primary-color);
          padding: 8px 15px;
          border: 1px solid transparent;
          outline: 0;
          border-radius: 10px;
          transition: all 0.4s linear;
          color: #fff;


          &:hover {
            background-color: transparent;
            border-color: var(--primary-color);
            color: #000;
          }
        }


      }
    }


  }


  .table_main_comun {
    margin-top: -90px;
    background-color: var(--card_bg);
    border-radius: 10px;
    border: 1px solid #dddddd94;
    margin-bottom: 30px;
    padding: 20px;
    border-radius: 10px;

    table {
      thead {
        tr {
          background: -webkit-linear-gradient(top, rgba(43, 56, 146, 0.6) 0%, rgba(43, 56, 146, 0.94) 100%);
          border-radius: 10px;

        }


        th {
          background-color: transparent;
          white-space: nowrap;
          padding: 10px 20px;
          // background: -webkit-linear-gradient(top, rgba(43, 56, 146, 0.6) 0%, rgba(43, 56, 146, 0.94) 100%);
          color: var(--txt-white);

        }

      }
    }

    tr {



      td {
        white-space: nowrap;
        padding: 10px 20px;
        font-size: 14px;
      }
    }
  }
}

.second-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;

  .form-select {
    background-color: transparent !important; 
    border: 1px solid #ddd;
    padding: 0.200rem 2.25rem 0.200rem 0.75rem;
    font-size: 14px;

    &:focus {
      box-shadow: unset;
      border-color: var(--primary-color);
    }
  }

  .buttons-s{
    display: flex;
    align-items: center;
    gap: 10px;

    button{
      border: 1px solid #ddd;
      display: inline-block;
      color: var(--txt-white);
      font-size: 14px;

    }
  }

}

.payout-off-on{
  max-width: 600px;
  width: 100%;
  padding: 20px;
  border-top: 2px dotted var(--primary-color);

  h6{
    margin-bottom: 20px;
  }

  .form-group{
    label{
      display: block;
      text-align: left;
      margin-bottom: 5px;
    }

    select{
      width: 100%;
      height: auto;
      padding: 10px;
      margin-bottom: 20px;
      box-shadow: unset;
      transition: all 0.4s linear;


      &:focus{
        border-color: var(--primary-color);
      }
     
    }
    textarea{
      box-shadow: unset;
      transition: all 0.4s linear;

      &:focus{
        border-color: var(--primary-color);
      }

    }
  }

  .btn{
    width: 100%;
    display: inline-block;
    margin: 20px 0;
  }
}