@import url("https://fonts.googleapis.com/css2?family=Nosifer&family=Nova+Square&display=swap");

html {
  scroll-behavior: smooth;
}

body {
  scroll-behavior: smooth;
  background-color: var(--body);
  height: 100vh;
}

$font_family_1: "Nosifer",
sans-serif;
$font_family_2: "Nova Square",
sans-serif;
//breakpoints
$breakpoint_xxl: "max-width:1700px";
$breckpoint_XXL: "max-width: 1400px";
$breckpoint_XL: "max-width: 1200px";
$breckpoint_LG: "max-width: 992px";
$breckpoint_MD: "max-width: 767px";
$breckpoint_SM: "max-width: 576px";
$breckpoint_ESM: "max-width: 450px";

$primary-color: var(--primary-color);

main {
  height: 100vh;
  width: 100%;
}

.margin_top {
  margin-top: 130px;
  margin-bottom: 80px;

  @media($breckpoint_SM) {
    margin-top: 110px;
  }
}

.coustom_container {
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
  padding: 0 100px;

  @media ($breckpoint_XL) {
    padding: 0 70px;
  }

  @media ($breckpoint_LG) {
    padding: 0 30px;
  }

  @media ($breckpoint_MD) {
    padding: 0 18px;
  }

  @media ($breckpoint_SM) {
    padding: 0 15px;
  }

  .for-custom-money {
    position: relative;
    top: 9px;
    left: -32px;
    transition: 0.5s all;
    color: #1DAADB !important;

}
.card {
  &:hover {
    .for-custom-money{
      transition: 0.5s all;
      top: -4px;
      left: -43px;
    }
  }
  .card-header {
    padding: 15px;
  }
  .for-card-flex {
    display: flex;
  }
  .active-txt {
    padding-left: 30px;
  }
}

  }


// page info css here

.page_info {
  padding: 16px 0;
  border-bottom: 1px solid var(--border_color);
  width: 80%;
  margin-top: 82px !important;
  margin: auto;
  border-radius: 0 0 50% 50%;

  @media ($breckpoint_ESM) {
    border-radius: 0;
    width: 100%;
  }

  h6 {
    margin-right: 15px !important;
    border-right: 1px solid var(--primary-color);
    color: var(--primary-color);
    font-weight: 700;
    margin: 0;
    padding: 8px 16px 8px 0;

    @media ($breckpoint_SM) {
      display: none;
    }
  }

  .inner_links_header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    color: var(--txt-white);

    .dot {
      font-size: 10px;
      color: var(--txt-black);
    }

    svg {
      color: var(--primary-color);
    }

    a {
      color: var(--txt-black);
      font-size: 14px;
      font-weight: 400;
      text-decoration: none;
      transition: all 0.3s linear;

      svg {
        @media ($breckpoint_SM) {
          font-size: 18px;
        }
      }

      @media ($breckpoint_SM) {
        font-size: 15px;
      }

      &:hover {
        color: var(--primary-color);
      }
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: var(--txt-white);
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #23232300;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.search_button {
  padding: 5px 10px;
  font-size: 13px;
  color: var(--txt-white);
  background-color: var(--primary-color);
  border: 1px solid transparent;
  border-radius: 5px;
  margin-left: 10px;
}

.input-fields {
  width: 100%;
  padding: 10px;
  color: var(--txt-black);
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid var(--border_color);
  outline: none;
  font-weight: 500;
  font-size: 14px;

  &::placeholder {
    color: var(--txt-white);
  }

  &:focus {
    border-color: var(--primary-color);
  }
}

.yellow_button {
  transition: all 0.3s linear;
  background-color: #f8b425 !important;

  &:hover {
    background-color: #e39c07 !important;
  }
}

.red_button {
  transition: all 0.3s linear;
  background-color: #ec4561 !important;

  &:hover {
    background-color: #e6183a !important;
  }
}

.pagination__2 {
  display: flex;
  gap: 8px;
  list-style: none;
  justify-content: center;
  margin-top: 30px;


  @media($breckpoint_SM) {
    justify-content: center;
    margin-top: 20px;
  }

  @media($breckpoint_ESM) {
    gap: 5px;
  }


  // .previous {
  //   a {
  //     border-radius: 50px 5px 5px 50px;
  //     padding: 8px 20px
  //   }
  // }

  // .next {
  //   a {
  //     border-radius: 5px 50px 50px 5px;
  //     padding: 8px 20px
  //   }
  // }

  .active {

    a {
      background-color: var(--secondary-color);
      color: var(--txt-white);
    }

  }

  li {
    a {
      display: inline-block;
      background-color: #f5f5f5;
      border: 1px solid #ddd;
      border-radius: 5px;
      padding: 7px 10px;
      font-size: 14px;
      color: var(--txt-black) !important;
      text-decoration: none;
      transition: all 0.4s linear;


      &:hover{
        background-color: transparent;
        color: #000;
        border-color: var(--primary-color);
      }

      @media($breckpoint_ESM) {
        padding: 5px 10px;
        font-size: 14px;
      }
    }
  }
}



.main_button {
  background-color: var(--primary-color);
  color: var(--txt-white);
  border-radius: 8px;
  border: 1px solid var(--primary-color);
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
  font-weight: 500;

  &:hover {
    opacity: .8;
  }

}

.logout_button {
  padding: 7px 30px;
  font-size: 14px;
  color: var(--txt-white);
  background-color: var(--primary-color);
  border: 1px solid transparent;
  border-radius: 5px;
  margin-left: 15px;

  &:hover {
    border-color: var(--primary-color);
    background-color: transparent;
    color: white;
  }
}

.main_class {
  margin-bottom: 100px;
}

// box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05);

.LoadingBar_contain {
  top: 80px !important;
  z-index: 98 !important;
  height: 5px !important;

}

.loader-bg {
  background-color: #ecececd6;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 97;
  backdrop-filter: blur(1px);
  transition: all 0.3s linear;

}


.news-sec {
  margin: 40px 0 0;

  .form-label {
    margin-bottom: 10px;
  }

  .form-control {
    background-color: transparent;
    border-color: var(--primary-color);
    color: var(--txt-black);
    border-radius: 10px;
    box-shadow: unset;
    padding: 12px 10px;
  }

  .submit-btn {
    width: 50%;
    display: block;
    margin: 40px auto 0;
    background-color: var(--primary-color);
    color: var(--txt-white);
    padding: 10px;
    border-radius: 40px;
    text-transform: capitalize;
  }

}

.no-data-js {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 50%;
    height: 50%;
  }
}


// ========================
// ========================
// ========================



.switch {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 65px;
  height: 30px;
  padding: 1px;
  border-radius: 3px;
  cursor: pointer;
}



.switch-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.switch-label {
  position: relative;
  display: block;
  height: inherit;
  font-size: 10px;
  text-transform: uppercase;
  background: var(--bs-danger);
  border-radius: inherit;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12), inset 0 0 2px rgba(0, 0, 0, 0.15);
  -webkit-transition: 0.15s ease-out;
  -moz-transition: 0.15s ease-out;
  -o-transition: 0.15s ease-out;
  transition: 0.15s ease-out;
  -webkit-transition-property: opacity background;
  -moz-transition-property: opacity background;
  -o-transition-property: opacity background;
  transition-property: opacity background;
}

.switch-label:before,
.switch-label:after {
  position: absolute;
  top: 50%;
  margin-top: -.5em;
  line-height: 1;
  -webkit-transition: inherit;
  -moz-transition: inherit;
  -o-transition: inherit;
  transition: inherit;
}

.switch-label:before {
  content: attr(data-off);
  right: 9px;
  color: #fff;
  text-shadow: 0 1px rgba(255, 255, 255, 0.5);
}

.switch-label:after {
  content: attr(data-on);
  left: 11px;
  color: white;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
  opacity: 0;
}

.switch-input:checked~.switch-label {
  // background: var(--bs-danger);
  background: var(--bs-success);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15), inset 0 0 3px rgba(0, 0, 0, 0.2);
}

.switch-input:checked~.switch-label:before {
  opacity: 0;
}

.switch-input:checked~.switch-label:after {
  opacity: 1;
}

.switch-handle {
  position: absolute;
  top: 1px;
  left: -5px;
  width: 30px;
  height: 30px;
  background: white;
  border-radius: 3px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  background-image: -webkit-linear-gradient(top, white 40%, #f0f0f0);
  background-image: -moz-linear-gradient(top, white 40%, #f0f0f0);
  background-image: -o-linear-gradient(top, white 40%, #f0f0f0);
  background-image: linear-gradient(to bottom, white 40%, #f0f0f0);
  -webkit-transition: left 0.15s ease-out;
  -moz-transition: left 0.15s ease-out;
  -o-transition: left 0.15s ease-out;
  transition: left 0.15s ease-out;
}

.switch-handle:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -6px 0 0 -6px;
  width: 12px;
  height: 12px;
  background: #f9f9f9;
  border-radius: 6px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.02);
  background-image: -webkit-linear-gradient(top, #eeeeee, white);
  background-image: -moz-linear-gradient(top, #eeeeee, white);
  background-image: -o-linear-gradient(top, #eeeeee, white);
  background-image: linear-gradient(to bottom, #eeeeee, white);
}

.switch-input:checked~.switch-handle {
  left: 35px;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
}

.switch-green>.switch-input:checked~.switch-label {
  background: #4fb845;
}


// all color button ring...

.color-ring {
  display: flex;
  justify-content: center;
  align-items: center;

  .loader-section {
    top: 0 !important;
    left: 0 !important;
    height: 10px !important;
    width: 10px !important;
    transform: scale(0.4);
    margin-right: 20px;
  }


}

.view-button {
  background-color: var(--primary-color);
  color: #fff;
  border: 1px solid transparent;
  padding: 6px 15px;
  font-size: 14px;
  transition: all 0.4s linear;

  &:hover,
  &:focus {
    background-color: transparent;
    color: #000;
    border-color: var(--primary-color);
  }
}

.btn-success{
  --btn-succss_sd:#1a8754;
  background-color: var(--btn-succss_sd) !important;
  color: #fff;
  border: 1px solid transparent;

  &:hover{
    color: var(--btn-succss_sd) !important;
    background-color: transparent !important;
    border-color: var(--btn-succss_sd) !important;
  }
}


.btn-danger{
  --btn-danger_sd: #dc3545;
  background-color: var(--btn-danger_sd) !important;
  color: #fff;

  &:hover{
    color: var(--btn-danger_sd) !important;
    background-color: transparent !important;
    border-color: var(--btn-danger_sd) !important;
  }
}